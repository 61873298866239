import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ArticleCard = ({ data, cardStyle, cardClassName }) => {

  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50">
          <Link to={`/article/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="wpo-article-item">
              <GatsbyImage
                image={getImage(data.frontmatter.image.card)}
                alt={data.frontmatter.image.alt}
              />
              {data.frontmatter.title &&
                <h2>{data.frontmatter.title}</h2>
              } 
              {data.frontmatter.description &&        
                <p className="desc">{data.frontmatter.description}</p>
              }
              <Link to={`/article/${data.slug}`} aria-label={data.frontmatter.image.alt}>Voir plus...</Link>
            </div>
          </Link>
        </div>
      } 
    </>
  )
}

export default ArticleCard