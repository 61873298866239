import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Gallery, Item } from 'react-photoswipe-gallery';

// Components
import Layout from '../../components/layout/Layout';
import ArticleCard from '../../components/card/Article';

// Article
const ArticlePage = ({ pageContext, data }) => {

  const location = useLocation();
  const origin = location.origin;

  const pageImage = { 
    url: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    type: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.type : null,
    width: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.gatsbyImageData.width : null,
    height: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.gatsbyImageData.height : null,
    alt: data.article.frontmatter.image.alt ? data.article.frontmatter.image.alt : null
  };

  const pageSchemas = [
    { 
      type: "article",
      title: data.article.frontmatter.meta.title,
      description: data.article.frontmatter.meta.description,
      cover: data.article.frontmatter.image.cover,
      images: data.article.frontmatter.images,
      date_published: data.article.frontmatter.date_published,
      date_modified: data.article.frontmatter.date_modified,
      author_name: data.author ? data.publisher.frontmatter.name : null,
      author_url: data.author ? data.publisher.frontmatter.url : null,
      publisher_name: data.publisher ? data.publisher.frontmatter.name : null,
      publisher_url: data.publisher ? data.publisher.frontmatter.url : null,
      publisher_logo_url: data.publisher ? data.publisher.frontmatter.logo_url : null,
    },
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: "Articles",
          item: origin + "/articles/"
        },
        { 
          name: data.article.frontmatter.title
        },
      ]
    }
  ];

  const headerBackgroundImage = data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`${data.article.frontmatter.meta.title} | ${data.site.siteMetadata.title} - Un cercle de qualité`}
      pageDescription={data.article.frontmatter.meta.description} 
      pageKeywords={data.article.frontmatter.meta.keywords} 
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                {data.article.frontmatter.title &&
                  <h2>{data.article.frontmatter.title}</h2>
                }
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li><Link to={`/articles/`}>Articles</Link></li>
                  {data.article.frontmatter.title &&
                    <li>{data.article.frontmatter.title}</li>
                  }
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="wpo-article-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            {data.publisher &&
              <div className="col-lg-4 col-12 order-first">
                <div className="blog-sidebar">
                  <div className="wpo-article-single-content-des-left">
                    <div>
                      <GatsbyImage
                        image={getImage(data.publisher.frontmatter.image.card)}
                        alt={data.publisher.frontmatter.image.alt}
                      />
                    </div>
                    <ul>
                      {data.publisher.frontmatter.country &&
                        <li key="country">
                          <span><i className="bi bi-geo-alt-fill"> </i> </span>
                          {data.publisher.frontmatter.country}
                        </li>
                      }
                      {data.publisher.frontmatter.website &&
                        <li key="website">
                          <span><i className="bi bi-globe"> </i> </span>
                          <a href={data.publisher.frontmatter.website} target="_blank" rel="noopener noreferrer" aria-label="website">{data.publisher.frontmatter.website}</a>
                        </li>
                      }
                      {data.publisher.frontmatter.email &&
                        <li key="email">
                          <span><i className="bi bi-envelope"> </i> </span>
                          <a href={"mailto:" + data.publisher.frontmatter.email}>{data.publisher.frontmatter.email}</a>
                        </li>
                      }
                      {data.publisher.frontmatter.telephone &&
                        <li key="telephone">
                          <span><i className="bi bi-telephone"></i></span>
                          <a href={"tel:" + data.publisher.frontmatter.telephone}>{data.publisher.frontmatter.telephone} </a>                                
                        </li>
                      }
                    </ul>
                    {data.publisher.frontmatter.social_networks &&
                      <ul className="social-links">
                        {data.publisher.frontmatter.social_networks.linkedIn &&
                          <li key="linkedin"><a href={data.publisher.frontmatter.social_networks.linkedIn} target="_blank" rel="noopener noreferrer" aria-label="linkedin"><i className="bi bi-linkedin"></i></a></li>
                        }
                        {data.publisher.frontmatter.social_networks.facebook &&
                          <li key="facebook"><a href={data.publisher.frontmatter.social_networks.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook"><i className="bi bi-facebook"></i></a></li>
                        }
                        {data.publisher.frontmatter.social_networks.instagram &&
                          <li key="instagram"><a href={data.publisher.frontmatter.social_networks.instagram} target="_blank" rel="noopener noreferrer" aria-label="instagram"><i className="bi bi-instagram"></i></a></li>
                        }
                        {data.publisher.frontmatter.social_networks.twitter &&
                          <li key="twitter"><a href={data.publisher.frontmatter.social_networks.twitter} target="_blank" rel="noopener noreferrer" aria-label="twitter"><i className="bi bi-twitter"></i></a></li>
                        }
                      </ul>
                    }
                  </div>
                </div>
              </div>
            }
            <div className="col-lg-8 col-12 order-lg-first">
              <div className="wpo-article-single-wrap">
                <div className="wpo-article-single-item">
                  <MDXProvider
                    components={{
                      h2: props => <div className="wpo-article-single-title"><h2 {...props}>{props.children}</h2></div>,
                      h3: props => <div className="wpo-article-single-title"><h3 {...props}>{props.children}</h3></div>,
                      h4: props => <div className="wpo-article-single-title"><h4 {...props}>{props.children}</h4></div>,
                      h5: props => <div className="wpo-article-single-title"><h5 {...props}>{props.children}</h5></div>,
                      ul: props => <div className="list-widget"><ul {...props}>{props.children}</ul></div>,
                      a: props => <a {...props} className="text-highlight-warning" target="_blank" rel="noopener noreferrer">{props.children}</a>,
                    }}
                  >
                    <MDXRenderer
                      localImages={data.article.frontmatter.images}
                    >
                      {data.article.body}
                    </MDXRenderer>
                  </MDXProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.article.frontmatter.galleries && data.article.frontmatter.galleries.length ?
        <div className="wpo-article-single-item">
          <div className="container">
            <div className="wpo-article-single-gallery">
              <div className="wpo-article-single-title">
                  <h3>Galerie</h3>
              </div>
              <div className="row mt-4">
                <Gallery>
                  {data.article.frontmatter.galleries.map((gallery, index) => (
                    <>
                      {gallery && gallery.childImageSharp &&
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="wpo-p-details-img">
                            <Item
                              original={gallery.childImageSharp.fluid.srcWebp}
                              thumbnail={gallery.childImageSharp.fluid.srcWebp}
                              width={gallery.childImageSharp.original.width}
                              height={gallery.childImageSharp.original.height}
                            >
                              {({ ref, open }) => (
                                <div role="none" ref={ref} onClick={open} onKeyDown={open}>
                                  <GatsbyImage
                                    ref={ref}
                                    onClick={open}
                                    image={getImage(gallery)}
                                    alt={data.article.frontmatter.image.alt}
                                  />
                                  <span role="none" ref={ref} onClick={open} onKeyDown={open}></span>
                                </div>
                              )}
                            </Item>
                          </div>
                        </div>
                      }
                    </>
                  ))}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      : null}
      {data.otherArticles && data.otherArticles.nodes.length ?
        <div className="wpo-article-area section-padding">
          <div className="container">
            <div className="row">
              <div className="wpo-section-title">
                <span>Découvrez tous les autres articles de presse concernant {data.site.siteMetadata.title}.</span>
                <h2>Nos autres articles</h2>
              </div>
            </div>
            <div className="row align-items-center">
              {data.otherArticles.nodes.map(article => (
                <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-lg-4 col-md-6 col-12" />
              ))}
            </div>
          </div>
        </div>
      : null}
    </Layout>
  )
}

ArticlePage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    article: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export const query = graphql`
  query ($id: String!, $publisher: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    article: mdx(id: {eq: $id}, fileAbsolutePath: { regex: "/data/articles/" }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }        
        title
        description
        publisher
        type
        link
        image {
          cover {
            childImageSharp {
              gatsbyImageData
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          credit {
            text
            link
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        galleries {
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home
        active
        date(formatString: "D MMMM YYYY", locale: "fr")
      }
      body
    }
    publisher: mdx(slug: {eq: $publisher}, fileAbsolutePath: { regex: "/data/publishers/" }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }        
        name
        description
        country
        website
        social_networks {
          linkedIn
          facebook
          instagram
          twitter
        }
        image {
          card {
            childImageSharp {
              gatsbyImageData
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          credit {
            text
            link
          }
        }
        galleries {
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home
        active
        date(formatString: "D MMMM YYYY", locale: "fr")
      }
      body
    }
    otherArticles: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" }, 
        id: {ne: $id},
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___name, order: ASC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          name
          description
          image {
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
    }
  }
`

export default ArticlePage